import axios from "axios";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import EditModal from "../../components/EditModal";
import PaginationControls from "../../components/Pagination";



function ProjectsPage() {
  const [projects, setProjects] = useState([]);
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [modalData, setModalData] = useState(null);
  const [modalType, setModalType] = useState('');

  // Pagination state
  const [currentPageProjects, setCurrentPageProjects] = useState(1);
  const [currentPageCategories, setCurrentPageCategories] = useState(1);
  const [currentPageSubcategories, setCurrentPageSubcategories] = useState(1);
  // Row per page state
  const [rowsPerPageProjects, setRowsPerPageProjects] = useState(5);
  const [rowsPerPageCategories, setRowsPerPageCategories] = useState(5);
  const [rowsPerPageSubcategories, setRowsPerPageSubcategories] = useState(10);

  // const rowsPerPage = 10;


  useEffect(() => {
    // Fetch projects
    axios.get('https://sf.doaguru.com//api/projects')
      .then(response => {
        setProjects(response.data);
      })
      .catch(error => {
        console.error('Error fetching projects:', error);
      });

    // Fetch categories and their subcategories
    axios.get('https://sf.doaguru.com//api/category-list')
      .then(response => {
        setCategories(response.data);

        // Reset subcategories to avoid duplication
        const allSubcategories = [];
        response.data.forEach(category => {
          gettingSubCategory(category.id, allSubcategories);
        });
      })
      .catch(error => {
        console.error('Error fetching categories:', error);
      });
  }, []);

  const gettingSubCategory = (selectedCategory, allSubcategories) => {
    axios.get(`https://sf.doaguru.com//api/sub-category-list?category_id=${selectedCategory}`)
      .then(response => {
        // Add subcategories to the array
        allSubcategories.push(...response.data);

        // Set the final subcategories after all are fetched
        setSubcategories([...allSubcategories]);

        console.log(response.data, 'line51');
      })
      .catch(error => {
        console.error('Error fetching subcategories:', error);
      });
  };

  //Pagination function 
  const getTotalPages = (data, rowsPerPage) => Math.ceil(data.length / rowsPerPage);
  const getCurrentRows = (data, currentPage, rowsPerPage) => {
    const indexOfLastRow = currentPage * rowsPerPage;
    const indexOfFirstRow = indexOfLastRow - rowsPerPage;
    return data.slice(indexOfFirstRow, indexOfLastRow);
  };

  const handleNextPage = (setter, currentPage, totalPages) => {
    if (currentPage < totalPages) {
      setter(currentPage + 1);
    }
  };

  const handlePreviousPage = (setter, currentPage) => {
    if (currentPage > 1) {
      setter(currentPage - 1);
    }
  };
// handle row per page in table 
const handleRowsPerPage = (event, setter) => {
  setter(parseInt(event.target.value));
};

  // Function to handle opening the modal

  const handleEditClick = (item, type) => {
    // const { name } = item;
    console.log('Item:', item);
    console.log('Type:', type);
    setModalData(item);
    console.log(setModalData, 'line 66');

    setModalType(type);
  };

  // Function to handle form submission in the modal
  const handleEditSubmit = (updatedData) => {
    // Send a request to update the data in the database
    console.log(updatedData);

    axios.post(`https://sf.doaguru.com//api/update-${modalType}`, updatedData)
      .then(response => {
        // Handle the response and update the UI
        toast.success(`${modalType} updated successfully`, { position: 'top-right' });
        // Update the state with the new data
        if (modalType === 'projects') {
          setProjects(projects.map(project => project.id === updatedData.id ? updatedData : project));
        } else if (modalType === 'category') {
          setCategories(categories.map(category => category.id === updatedData.id ? updatedData : category));
        } else if (modalType === 'subcategory') {
          setSubcategories(subcategories.map(subcategory => subcategory.id === updatedData.id ? updatedData : subcategory));
        }
        setModalData(null); // Close the modal
      })
      .catch(error => {
        console.error('Error updating:', error);
        toast.error(`Failed to update ${modalType}`, { position: 'top-right' });
      });
  };

  // Remove projects and other  handle 
  const handleDeleteTask = (id, type) => {
    // Check condition confirmation 
    const isConfirmed = window.confirm(`Are you sure you want to remove this ${type}?`);

    // Proceed with deletion if confirmed
    if (isConfirmed) {
      axios.post(`https://sf.doaguru.com//api/delete-${type}`, { id })
        .then(response => {

          toast.success(`${type} removed successfully`, { position: 'top-right' });

          // Update the UI by removing the deleted item from the state
          if (type === 'projects') {
            setProjects(projects.filter(project => project.id !== id));
          } else if (type === 'categorys') {
            setCategories(categories.filter(category => category.id !== id));

            // Also remove forigen subcategories
            setSubcategories(subcategories.filter(subcategory => subcategory.category_id !== id));

          } else if (type === 'subcategory') {
            setSubcategories(subcategories.filter(subcategory => subcategory.id !== id));
          }
        })
        .catch(error => {
          console.error('There was an error!', error);

          toast.error(
            type === 'subcategory'
              ? `Failed to remove ${type} because subcategories are dependent on Category`
              : `Failed to remove ${type}`,
            { position: 'top-right' }
          );
        });
    }
  };


  useEffect(() => {

  }, [projects])

  return (
    <div className="flex justify-center m-auto">
      <div className="EmployeePage container flex flex-col gap-2 justify-center sm:m-5 sm:p-5 mt-16">
        <div className="flex justify-start">
          <span className="m-1 p-2 hover:bg-cyan-100 border border-cyan-600 rounded-lg">
            <Link to="/task/AddProject">Register Projects</Link>
          </span>
        </div>
        <div className="flex">
          <h1 className="m-auto font-bold">Project Details</h1>
        </div>
        <div className="allTable flex flex-wrap justify-center">


          {/* Projects Table */}
          <div className="projectsTable">
            <h2 className="text-lg font-semibold mt-4 mb-2">Projects</h2>
            <div className="relative mx-4 overflow-x-auto shadow-md rounded-lg">
              <div className=" Select-table-row mb-0.5">
                <span className="text-xs">Select Page </span>
                <select name="rowsPerPage" id="rowsPerPage" className="text-xs rounded border-1 " onChange={(e) => handleRowsPerPage(e, setRowsPerPageProjects)} 
                value={rowsPerPageProjects}>
                  <option value="5">5</option>
                  <option value="10">10</option>
                  <option value="20">20</option>
                </select>
              </div>
              <table className="w-full text-sm text-left text-gray-700 dark:text-gray-400">
                <thead className="text-xs text-gray-700 uppercase bg-gray-200 dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                    <th scope="col" className="px-3 py-2">Project ID</th>
                    <th scope="col" className="px-3 py-2">Project Name</th>
                    <th scope="col" className="px-3 py-2">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {getCurrentRows(projects, currentPageProjects, rowsPerPageProjects).map((project, index) => (
                    <tr key={project.id} className="odd:bg-white even:bg-gray-50 dark:odd:bg-gray-900 dark:even:bg-gray-800 border-b dark:border-gray-700">
                      <td className="px-3 py-2">{project.id}</td>
                      <td className="px-3 py-2">{project.name}</td>
                      <td className="px-3 py-2 flex">
                        <Link to="#" onClick={() => handleEditClick(project, 'projects')} className="font-medium text-blue-600 dark:text-blue-500 hover:underline px-1">Edit</Link>
                        <Link to="#" onClick={() => handleDeleteTask(project.id, 'projects')} className="font-medium text-red-600 dark:text-red-500 hover:underline px-1">Remove</Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {getTotalPages(projects, rowsPerPageProjects) > 1 && (
                <PaginationControls
                  currentPage={currentPageProjects}
                  totalPages={getTotalPages(projects, rowsPerPageProjects)}
                  onNextPage={() => handleNextPage(setCurrentPageProjects, currentPageProjects, getTotalPages(projects, rowsPerPageProjects))}
                  onPreviousPage={() => handlePreviousPage(setCurrentPageProjects, currentPageProjects)}
                />
              )}
            </div>
          </div>

          {/* Categories Table */}
          <div className="CategoriesTable">
            <h2 className="text-lg font-semibold mt-4 mb-2">Categories</h2>
            <div className="relative mx-4 overflow-x-auto shadow-md rounded-lg">
            <div className="mb-0.5">
                <span className="text-xs">Select Rows Per Page </span>
                <select name="rowsPerPage" id="rowsPerPage" className="text-xs rounded border-1 "
                onChange={(e) => handleRowsPerPage(e, setRowsPerPageCategories)} 
                value={rowsPerPageCategories}>
                  <option value="5">5</option>
                  <option value="10">10</option>
                  <option value="20">20</option>
                </select>
              </div>
              <table className="w-full text-sm text-left text-gray-700 dark:text-gray-400">
                <thead className="text-xs text-gray-700 uppercase bg-gray-200 dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                    <th scope="col" className="px-3 py-2">Category ID</th>
                    <th scope="col" className="px-3 py-2">Category Name</th>
                    <th scope="col" className="px-3 py-2">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {getCurrentRows(categories, currentPageCategories, rowsPerPageCategories).map(category => (
                    <tr key={category.id} className="odd:bg-white even:bg-gray-50 dark:odd:bg-gray-900 dark:even:bg-gray-800 border-b dark:border-gray-700">
                      <td className="px-3 py-2">{category.id}</td>
                      <td className="px-3 py-2">{category.name}</td>
                      <td className="px-3 py-2 flex">
                        <Link to="#" onClick={() => handleEditClick(category, 'category')} className="font-medium text-blue-600 dark:text-blue-500 hover:underline px-1">Edit</Link>
                        <Link to="#" onClick={() => handleDeleteTask(category.id, 'categorys')} className="font-medium text-red-600 dark:text-red-500 hover:underline px-1">Remove</Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {/* Pagination Controls for Categories */}
              {getTotalPages(categories, rowsPerPageCategories) > 1 && (
                <PaginationControls
                  currentPage={currentPageCategories}
                  totalPages={getTotalPages(categories, rowsPerPageCategories)}
                  onNext={() => handleNextPage(setCurrentPageCategories, currentPageCategories, getTotalPages(categories, rowsPerPageCategories))}
                  onPrevious={() => handlePreviousPage(setCurrentPageCategories)}
                />
              )}
            </div>
          </div>

          {/* Subcategories Table */}
          <div className="SubcategoriesTable">
            <h2 className="text-lg font-semibold mt-4 mb-2">Subcategories</h2>
            <div className="relative mx-4 overflow-x-auto shadow-md rounded-lg">
            <div className="mb-0.5">
                <span className="text-xs">Select Rows Per Page </span>
                <select name="rowsPerPage" id="rowsPerPage" className="text-xs rounded border-1 " 
                onChange={(e) => handleRowsPerPage(e, setRowsPerPageSubcategories)} 
                value={rowsPerPageSubcategories}>
                  <option value="5">5</option>
                  <option value="10">10</option>
                  <option value="20">20</option>
                </select>
              </div>
              <table className="w-full text-sm text-left text-gray-700 dark:text-gray-400">
                <thead className="text-xs text-gray-700 uppercase bg-gray-200 dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                    {/* <th scope="col" className="px-3 py-2">S.no</th> */}
                    <th scope="col" className="px-3 py-2">Subcategory ID</th>
                    <th scope="col" className="px-3 py-2">Subcategory Name</th>
                    <th scope="col" className="px-3 py-2">Category ID</th>
                    <th scope="col" className="px-3 py-2">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {getCurrentRows(subcategories, currentPageSubcategories, rowsPerPageSubcategories).map((subcategory, index) => (
                    <tr key={subcategory.id} className="odd:bg-white even:bg-gray-50 dark:odd:bg-gray-900 dark:even:bg-gray-800 border-b dark:border-gray-700">
                      {/* <td className="px-3 py-2">{index+1}</td> */}
                      <td className="px-3 py-2">{subcategory.id}</td>
                      <td className="px-3 py-2">{subcategory.name}</td>
                      <td className="px-3 py-2">{subcategory.category_id}</td>
                      <td className="px-3 py-2 flex">
                        <Link to="#" onClick={() => handleEditClick(subcategory, 'subcategory')} className="font-medium text-blue-600 dark:text-blue-500 hover:underline px-1">Edit</Link>
                        <Link to="#" onClick={() => handleDeleteTask(subcategory.id, 'subcategory')} className="font-medium text-red-600 dark:text-red-500 hover:underline px-1">Remove</Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

              {/* Pagination Controls */}
              {/* Pagination Controls for Categories */}
              {getTotalPages(subcategories, rowsPerPageSubcategories) > 1 && (
                <PaginationControls
                  currentPage={currentPageSubcategories}
                  totalPages={getTotalPages(subcategories, rowsPerPageSubcategories)}
                  onNext={() => handleNextPage(setCurrentPageSubcategories, currentPageSubcategories, getTotalPages(subcategories, rowsPerPageSubcategories))}
                  onPrevious={() => handlePreviousPage(setCurrentPageSubcategories, currentPageSubcategories)}
                />
              )}
            </div>
          </div>

          {/* Edit Modal */}

          <EditModal
            show={modalData !== null}
            onClose={() => setModalData(null)}
            onSubmit={handleEditSubmit}
            item={modalData}
            type={modalType}
          />
        </div>


      </div>
    </div>
  );
}

export default ProjectsPage;
